.script-title {
    text-align: left;
    width: 95%;
    margin-bottom: 40px;
}

.script-container {
    position: relative;
    top: 40px;
    padding: 20px;
    width: 70vw;
    height: 70vh;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: auto;
    background: rgba(from var(--primary-color) r g b / 47%);
    border-radius: 36px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(from var(--primary-color) r g b / 30%);
}

.script-form {
    display: flex;
    flex-direction: column;
    gap: 50px;
    width: 95%;
}

.tooltip-icon {
    display: inline-flex;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--secondary-color);
    color: white;
    font-size: 24px;
    cursor: help;
    margin-left: 70px;
}

.script-select {
    width: 90%;
    height: 80px;
    border-radius: 50px;
    padding: 20px;
    margin: 0 auto;
    background-color: var(--background-color);
    color: var(--text-color);
    font-size: 16px;
    border: none;
}

.arguments-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
    width: 85%;
    margin: 0 auto;
}

.argument-input {
    padding: 10px;
    border-radius: 50px;
    background-color: var(--background-color);
    color: var(--text-color);
    font-size: 16px;
    cursor: pointer;
}

.button-container {
    display: flex;
    justify-content: flex-end;
    width: 95%;
}

.submit-button {
    padding: 20px;
    font-size: 20px;
    border-radius: 50px;
    cursor: pointer;
    background-color: var(--secondary-color);
    color: white;
    border: none;
    width: 300px;
    height: auto;
    transition: background-color 0.3s;
}

.submit-button:hover {
    background-color: var(--secondary-color);
}

.submit-button:active {
    transform: scale(0.98);
}
