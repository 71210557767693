.loading-spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .loading-spinner {
    width: 5vw;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  