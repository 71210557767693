.header {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Cela pousse les éléments aux extrémités du conteneur */
    padding: 60px !important;
    background-color: var(--background-color) !important;
    border-bottom: 0px solid var(--secondary-color);
}

.right-content {
    /* Styles pour le contenu à droite */
    display: flex;
    align-items: center;
}

.refresh-button {
    padding: 7px;
    margin-left: 300px; /* Ajoute un espace à gauche du bouton */
    border-radius: 50px;
    cursor: pointer;
    color: white;
    border: none;
    background-color: var(--background-color);
    transition: background-color 0.3s;
}
