.version-change-container {
    width: 100%;
    max-width: 600px;
    margin: auto;
    padding: 20px;
    background-color: var(--primary-color);
    border-radius: 8px;
}

.version-change-title {
    color: white;
    text-align: center;
    margin-bottom: 20px;
}

.version-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.version-label {
    color: white;

    margin-bottom: 5px;
    font-weight: bold;
}

.version-select {
    padding: 8px 12px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.version-submit-btn {
    background-color: var(--secondary-color);
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
}

.version-error {
    margin-top: 10px;
    font-size: 0.9rem;
    text-align: center;
}

.log-progress {
    margin-top: 50px;
    width: 100%;
    height: 20px;
    border-radius: 10px;
    /* Ajoute plus de styles selon tes besoins */
}
