.client-list-container {
    margin: 20px;
    font-family: Arial, sans-serif;
  }
  
  .expand-btn {
    background-color: var(--background-color);;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 5px 0;
    display: block;
    width: 100%;
    text-align: left;
  }
  
  .section-title {
    background-color:var(--secondary-color);
    color: var(--background-color);
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: block;
    width: 100%;
    text-align: left;
    transition: background-color 0.3s ease;
  }

  .expand-btn:hover {
    background-color: var(--background-color);;
  }
  
  .client-list ul {
    list-style: none;
    padding-left: 0;
    margin-top: 10px;
  }
  
  .client-list ul li {
    background-color: var(--primary-color);;
    border: 1px solid #ddd;
    padding: 8px;
    margin-bottom: 5px;
    border-radius: 5px;
  }
  
  .client-list div {
    margin-bottom: 20px;
  }
  
  /* Ajoute un peu de style pour le symbole d'expansion */
  .expand-btn::after {
    content: '';
    float: right;
    margin-left: 5px;
  }
  
  .expand-btn[aria-expanded="true"]::after {
    content: '−';
  }
  
  .expand-btn[aria-expanded="false"]::after {
    content: '+';
  }
  