.getalert-counter-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.getalert-importance-indicators {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
}

.getalert-importance-indicator {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 5px 0;
}

.getalert-color-particule-container {
    display: flex;
    align-items: center;
    padding-left: 50px;
}

.getalert-color-particule {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
}

.getalert-count {
    font-size: 16px;
    color: var(--text-color);
}

h2 {
    font-size: 20px;
    color: var(--text-color);
}
