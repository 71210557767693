.btn-secondary {
    background-color: var(--secondary-color);
    border-color: var(--secondary-color);
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
}

.btn-secondary:hover {
    background-color: #2e59d9;
    border-color: #2653d4;
    color: #fff;
    outline: none;
}
