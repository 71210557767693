.popup-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.popup-content {
    width: 80%;
    height: 80%;
    max-width: 640px;
    max-height: 480px;
    background-color: var(--primary-color);
    padding: 20px;
    border-radius: 50px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    overflow: auto;
    position: relative; 
}

.close-button {
    position: fixed;
    bottom: 270px;
    right: 670px; 
    border: none;
    cursor: pointer;
    background-color: var(--secondary-color);
    color: #FFF;
    padding: 10px 20px;
    border-radius: 10px;
    z-index: 100;
}
