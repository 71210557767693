.importance-indicator {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: inline-block;
}

.section-alert {
    cursor: pointer;
    padding: 25px 25px;
    margin: 20px;
    background-color: var(--primary-color);
    border-radius: 50px;
    transition: all 0.3s;
    display: flex;
    align-items: center;
    width: calc(100% - 10px);
}

.section-alert h4 {
    margin-left: 20px;
    margin-bottom: 0px;
    flex-grow: 1;
}

.section-alert p {
    margin-bottom: 3px;
    font-size: 0.9em;
    color: #666;
    margin-left: 10px;
}

.section-alert:hover {
    transform: translateY(-2px);
}
