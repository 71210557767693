.file-list-container {
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.file-list-container h3 {
    margin-bottom: 20px;
    color: white;
}

.file-list {
    list-style-type: none;
    padding: 0;
}

.file-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    margin-bottom: 10px;
    border-radius: 4px;
    transition: background-color 0.3s;
}

.download-button {
    background-color: #4CAF50;
    color: white;
    padding: 5px 10px;
    text-decoration: none;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.download-button:hover {
    background-color: #45a049;
}

.download-button:active {
    background-color: #3e8e41;
}

.error-message {
    color: red;
    font-weight: bold;
    margin-top: 20px;
}
