.form-container {
    position: relative;
    top: 40px;
    padding: 20px;
    border-radius: 50px;
    width: 40vw;
    min-width: 300px;
    height: min-content;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: auto;
    background: rgba(from var(--primary-color) r g b / 47%);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(from var(--primary-color) r g b / 30%);
}

form {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

label {
    flex: 1 1 45%;
    display: flex;
    flex-direction: column;
    font-size: 16px;
}

.form-container input[type="password"] {
    background-color: var(--background-color);
    color: #fff;
    border: 2px solid var(--background-color);
    padding: 19px;
    border-radius: 50px;
}


input, select {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.form-container label {
    display: block;
    margin-bottom: 10px;
}

.form-container input[type="text"],
.form-container select {
    width: 100%;
    padding: 20px;
    margin-top: 5px;
    color: var(--text-color);
    background-color: var(--background-color);
    border: 1px solid var(--background-color);
    border-radius: 50px;
}

.form-container button {
    width: 100%;
    padding: 10px;
    background-color: var(--secondary-color);
    color: white;
    border: none;
    border-radius: 16px;
    cursor: pointer;
    margin-top: 20px;
}