.overview-container {
    padding: 20px;
}

.sections-container-overview {
    display: grid;
    grid-template-columns: 0.1fr 0.1fr;
    grid-template-rows: 0.1fr 0.1fr;
    gap: 3vw;
    height: 80vh;
    padding: 60px;
    font-size: 20px;
    justify-content: center;
}

.section-overview {
    display: flex;
    justify-content: center;
    width: 25vw;
    min-width: 175px;
    align-items: center;
    padding: 23px;
    height: 26vh;
    min-height: 200px;
    --bg-alpha: 1;
    background: rgba(from var(--primary-color) r g b / 47%);
    border-radius: 36px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(from var(--primary-color) r g b / 30%);
}

.section-overview:hover {
    cursor: pointer;
}

.section-overview:active {
    transform: scale(0.98);
}

