.client-list-container {
    padding: 20px;
    background: rgba(from var(--primary-color) r g b / 47%);
    border-radius: 36px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(from var(--primary-color) r g b / 30%);
}

.menu-buttons {
    display: flex;
    width: 100%;
    font-size: 25px;
    justify-content: space-between;
    padding-bottom: 20px;
}

.menu-button {
    border-radius: 50px;
    flex-grow: 1;
    margin: 0 5px;
    padding: 10px 0;
    font-size: 1em;
    cursor: pointer;
    text-align: center;
    background-color: var(--background-color);
    border: none;
    color: var(--text-color);
}

.menu-button:first-child {
    margin-left: 0;
}

.menu-button:last-child {
    margin-right: 0;
}

.menu-button.active {
    background-color: var(--secondary-color);
    color: white;
}

.client-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    border-bottom: 1px solid var(--primary-color); /* Ajoute une ligne horizontale */
    padding-bottom: 10px; /* Ajoute un peu d'espace au-dessous des détails du client */
    margin-bottom: 10px; /* Espacement entre les éléments de la liste */
}

.client-details:last-child {
    border-bottom: none; /* Assure qu'il n'y ait pas de bordure sur le dernier élément */
}

.client-name h4 {
    margin-bottom: 5px;
    padding-left: 10px;
}

.button-group {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    margin-left: -67px;
}

.button-group button {
    padding: 10px;
    border-radius: 50px;
    cursor: pointer;
    background-color: var(--secondary-color);
    color: white;
    border: none;
    margin-left: 100px;
    transition: background-color 0.3s;
}
